
(function ($, NotificationBar) {

    'use strict';

    class NotificationBarController {
        constructor() {
            this.init();
        }

        assignNotificationBars(results) {
            this.notificationBars = results.map(_initNotificationBar);
        }

        collectSettings() {
            var siteHref = window.location.origin;

            var url = siteHref + '/wp-json/wp/v2/gd_notification_bar/';

            var settingsPromise = $.ajax(url);

            return settingsPromise;
        }

        init() {
            // Create session cookie if it doesn't already exist - reset expiration if it does exist
            var sessionCookie = document.cookie.split(';').filter((item) => item.trim().startsWith('gd.notbar.session='));
            if (sessionCookie && sessionCookie.length) {
                this.sessionStart = sessionCookie[0].replace('gd.notbar.session=', '');
            } else {
                var now = new Date();
                this.sessionStart = now.toISOString();
            }
            document.cookie = `gd.notbar.session=${this.sessionStart};max-age=${60*30}`;

            this.collectSettings()
                .then(this.assignNotificationBars.bind(this))
                .then(this.insertNotificationBars.bind(this));
        }

        insertNotificationBar(notBar) {
            let encodedTitle = encodeURI(notBar.title);

            // Check if this bar has been dismissed previously and if the dismissal period has expired
            if (notBar.postDismissalRenewalDelay) {
                let dismissalCookie = document.cookie.split(';').filter((item) => item.trim().startsWith(`gd.notbar.${encodedTitle}.dismissal=`));

                if (dismissalCookie && dismissalCookie.length) {
                    return;
                }
            }

            // Check if bar belongs on this page
            if (notBar.pages !== 'all') {
                var currentPage = window.location.pathname;
                var pageSlugs = notBar.pages.map(function urlToPath (fullUrl) {
                    let urlObj = new URL(fullUrl);
                    return urlObj.pathname;
                });
                if (!pageSlugs.includes(currentPage)) {
                    return;
                }
            }

            // Page loads check
            if (notBar.delayPageLoadsCount) {
                var pageLoadsCookie = document.cookie.split(';').filter((item) => item.trim().startsWith(`gd.notbar.${encodedTitle}.pageLoads=`));
                var pageLoadsCount = 1;

                if (pageLoadsCookie && pageLoadsCookie.length) {
                  pageLoadsCount += parseInt(pageLoadsCookie[0].replace(`gd.notbar.${encodedTitle}.pageLoads=`, ''));
                }

                document.cookie = `gd.notbar.${encodedTitle}.pageLoads=${pageLoadsCount};max-age=${60 * 30}`;

                if (pageLoadsCount < notBar.delayPageLoadsCount) {
                    return;
                }
            }

            // Time on site check
            if (notBar.delayTimeOnSite > 0) {
                var delayInMs = notBar.delayTimeOnSite * 1000;
                var timeOnSite = Math.abs(new Date() - new Date(this.sessionStart));

                if (timeOnSite < delayInMs) {
                    var remainder = delayInMs - timeOnSite;
                    window.setTimeout(_renderNotificationBar, remainder, notBar);
                } else {
                    _renderNotificationBar(notBar);
                }

                return;
            }

            // Delay after page load
            if (notBar.delayAfterPageLoad) {
                window.setTimeout(_renderNotificationBar, notBar.delayAfterPageLoad * 1000, notBar);
            } else {
                _renderNotificationBar(notBar);
            }

            notBar.bindEvents();
        }

        insertNotificationBars() {
            this.notificationBars.forEach(this.insertNotificationBar.bind(this));
        }
    }

    window.gdNotificationBar.ctrl = new NotificationBarController();

    /**
     * Private methods
     */

    function _initNotificationBar(result) {
        var data = {
            advanced: result.custom_fields['advanced'] || {},
            contentSettings: result.custom_fields['content'] || {},
            id: result.id,
            styleSettings: result.custom_fields['styling_section'] || {},
            title: result.title.rendered,
            viewSettings: result.custom_fields['view_settings'] || {}
        };

        return new NotificationBar(data);
    }

    function _renderNotificationBar(notBar) {
        var selector = notBar.positionCssSelector;
        var $element = $(selector);

        if (!$element) {
          console.error('Desired element for notification bar rendering not found. Aborting rendering for this bar.');
          return;
        }

        if (notBar.positionRelativePlacement === 'before') {
          $element.before(notBar.root);
        } else if (notBar.positionRelativePlacement === 'first_child') {
          $element.prepend(notBar.root);
        } else if (notBar.positionRelativePlacement === 'last_child') {
          $element.append(notBar.root);
        } else {
          $element.after(notBar.root);
        }

        notBar.bindEvents();

        if (notBar.autoDismissalDelay > 0) {
            window.setTimeout(notBar.onClickDismissal.bind(notBar), notBar.autoDismissalDelay * 1000);
        }
    }

})(jQuery, window.gdNotificationBar.NotificationBar);
