
(function ($) {

    'use strict';

    window.gdNotificationBar = window.gdNotificationBar || {};

    class NotificationBar {
        constructor(props) {
            this._vm = {};

            Object.assign(this, props);

            this.init();
        }

        get autoDismissalDelay() {
            return this.viewSettings['auto-dismissal_delay'] || this.default.autoDismissalDelay;
        }

        get backgroundColor() {
            return this.styleSettings['background_color'] || this.default.backgroundColor;
        }

        get buttonBackgroundColor() {
            return this.styleSettings['button_color'] || this.default.buttonBackgroundColor;
        }

        get buttonColor() {
            return this.styleSettings['button_text_color'] || this.default.buttonColor;
        }

        get buttonEnabled() {
            return this.contentSettings['button_enabled'] || this.default.buttonEnabled;
        }

        get buttonText() {
            return this.contentSettings['button_text'] || this.default.buttonText;
        }

        get buttonHoverBackgroundColor() {
            return this.styleSettings['button_hover_background_color'] || this.default.buttonHoverBackgroundColor;
        }

        get buttonHoverColor() {
            return this.styleSettings['button_hover_text_color'] || this.default.buttonHoverColor;
        }

        get buttonPosition() {
            return this.styleSettings['button_position'] || this.default.buttonPosition;
        }

        get color() {
            return this.styleSettings['text_color'] || this.default.color;
        }

        get customCss() {
            return this.advanced['custom_css'] || this.default.customCss;
        }

        get customCssEnabled() {
            return this.advanced['custom_css_enabled'] || this.default.customCssEnabled;
        }

        get dismissalButtonType() {
            return this.styleSettings['dismissal_button_type'] || this.default.dismissalButtonType;
        }

        get default() {
            return {
                autoDismissalDelay: false,
                backgroundColor: '#fff',
                buttonBackgroundColor: '#444',
                buttonColor: '#fff',
                buttonEnabled: false,
                buttonHoverBackgroundColor: '#555',
                buttonHoverColor: '#fff',
                buttonPosition: 'right',
                buttonText: '',
                color: '#333',
                customCss: '',
                customCssEnabled: false,
                delayAfterPageLoad: false,
                delayPageLoadsCount: false,
                delayTimeOnSite: false,
                dismissalButtonType: '&times;',
                hoverStateEnabled: false,
                link: false,
                maxWidth: '96em',
                message: '',
                paddingBottom: '0',
                paddingTop: '0',
                pages: 'all',
                positionCssSelector: '#siteHeader',
                positionRelativePlacement: 'before',
                postDismissalRenewalDelay: 1,
            };
        }

        get delayAfterPageLoad() {
            return this.viewSettings['delay_after_page_load'] || this.default.delayAfterPageLoad;
        }

        get delayPageLoadsCount() {
            return this.viewSettings['page_loads_delay'] || this.default.delayPageLoadsCount;
        }

        get delayTimeOnSite() {
            return this.viewSettings['time_on_site_delay'] || this.default.delayTimeOnSite;
        }

        get hoverStateEnabled() {
            return this.styleSettings['enable_button_hover_state'] || this.default.hoverStateEnabled;
        }

        get link() {
            return this.contentSettings['link_url'] || this.default.link;
        }

        get maxWidth() {
            return this.styleSettings['max_width'] || this.default.maxWidth;
        }

        get message() {
            return this.contentSettings['message'] || this.default.message;
        }

        get paddingBottom() {
            return this.styleSettings['padding_bottom'] || this.default.paddingBottom;
        }

        get paddingTop() {
            return this.styleSettings['padding_top'] || this.default.paddingTop;
        }

        get pages() {
            return this.viewSettings['pages'] || this.default.pages;
        }

        get positionCssSelector() {
            return this.viewSettings['page_position_css_selector'] || this.default.positionCssSelector;
        }

        get positionRelativePlacement() {
            return this.viewSettings['page_position_relative_placement'] || this.default.positionRelativePlacement;
        }

        get postDismissalRenewalDelay() {
            return this.viewSettings['post_dismissal_renewal_delay'] || this.default.postDismissalRenewalDelay;
        }

        get title() {
            return this._vm.title || '';
        }
        set title(title) {
            this._vm.title = title || '';
        }

        bindEvents() {
            this.selectElements();

            this.$dismissal.on('click', this.onClickDismissal.bind(this));

            this.$root.on('click', this.onClickContent.bind(this));
        }

        buildRoot() {
            var root = document.createElement('div');

            root.className = 'gdnb-wrapper';

            root.innerHTML = `
                <style>

                    #gdnb-${this.id} {
                        background-color: ${this.backgroundColor};
                        color: ${this.color};
                        cursor: ${this.link ? 'pointer' : 'default'};
                    }

                    #gdnb-${this.id} .gdnb__cta {
                        background-color: ${this.buttonBackgroundColor};
                        color: ${this.buttonColor};
                    }

                    ${this.hoverStateEnabled ?
                        `#gdnb-${this.id} .gdnb__cta:hover,
                        #gdnb-${this.id} .gdnb__cta:focus {
                            background-color: ${this.buttonHoverBackgroundColor};
                            color: ${this.buttonHoverColor};
                        }` :
                        ''
                    }

                    #gdnb-${this.id} .gdnb__content {
                        max-width: ${this.maxWidth};
                        padding-bottom: ${this.paddingBottom};
                        padding-top: ${this.paddingTop};
                    }

                    ${this.customCssEnabled ?
                        this.customCss.replace(/{{\s*id\s*}}/g, this.id) : ''
                    }

                </style>

                <div id="gdnb-${this.id}" class="gdnb${this.buttonEnabled ? 
                    ` gdnb--button-${this.buttonPosition}` : ''
                }">

                    <div class="gdnb__content">

                        <div class="gdnb__message">${this.message}</div>

                        ${this.buttonEnabled ? `<a class="gdnb__cta">${this.buttonText}</a>` : '' }

                    </div>

                    <div class="gdnb__dismissal">${this.dismissalButtonType.replace(
                        '&times;', '<span class="gdnb__dismissal-icon">&times;</span>'
                    )}</div>

                </div>
            `;

            return root;
        }

        init() {
            this.root = this.buildRoot();
        }

        onClickContent() {
            if (this.link) {
                location.href = this.link;
            }
        }

        onClickDismissal(event) {
            if (event) {
                event.preventDefault();
            }

            // Set cookie which expires when renewal delay is over
            let now = new Date();
            let endDate = new Date(now.getTime() + this.postDismissalRenewalDelay * 60 * 60 * 24 * 1000);
            document.cookie = `gd.notbar.${encodeURI(this.title)}.dismissal=true;expires=${endDate.toUTCString()}`;

            this.$root.remove();
        }

        /**
         * References to elements can't exist and/or need to change
         * once the root has been added to the actual DOM
         */
        selectElements() {

            this.$root = $(this.root);

            this.$dismissal = this.$root.find('.gdnb__dismissal');
        }
    }

    window.gdNotificationBar.NotificationBar = NotificationBar;

})(jQuery);
